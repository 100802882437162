import React, { useEffect } from 'react';

function NewGeneralTermsAndConditionPage() {
  useEffect(() => {
    if (window?.location) {
      const baseUrl = 'https://latitudefs.zendesk.com/hc/en-au/articles/32627445423761-We-re-updating-our-Latitude-Credit-Card-Terms-and-Conditions-FAQs';
      const queryParams = window.location.search;
      const redirectUrl = queryParams ? `${baseUrl}${queryParams}` : baseUrl;

      window.location.replace(redirectUrl);
    }
  }, []);

  return null;
}

export default NewGeneralTermsAndConditionPage;
